<template>
  <div class="error-page">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title">页面崩溃</span>
      </div>
      <article>
        <section class="qa">
          <h3>Q：为什么出现这个页面？</h3>
          <p>A：当你看到这个，说明服务器出故障了。</p>
        </section>
        <!-- <section class="qa">
          <h3>Q：为什么服务器会崩溃？</h3>
          <p>
            A：两个原因：<br /><span class="line">1.程序猿又写BUG了 ；</span
            ><br /><span class="line"
              >2.穷，服务器是最便宜的，人一多就爆；</span
            >
          </p>
        </section>-->
        <section class="qa">
          <h3>Q：你能做什么？</h3>
          <router-link to="/login" class="link">重新登录</router-link>
        </section>
      </article>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "Page500"
};
</script>

<style scoped>
.error-page {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.box-card {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 360px;
  height: 392px;
  margin: auto;
  /* //background: url(../../assets/img/common/mask1.png) no-repeat right top; */
  background-size: 112px;
}

.title {
  font-size: 18px;
}

.qa {
  margin: 20px 0;
  line-height: 1.6;
}

.line {
  margin-left: 22px;
}

.qa h3 {
  font-weight: bold;
  color: #333;
  margin-bottom: 4px;
}

.qa p {
  color: #666;
}

.link {
  display: block;
  color: #409eff;
  margin: 4px 4px 0 22px;
}
</style>